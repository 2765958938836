.search{
  display:flex;
  margin:auto;
}
.search input{
  width:500px;
}
.search button{
  background-color: royalblue;
  color: aliceblue;
  border:none;
}
.search button:hover{
  background-color:rgb(227, 6, 6);
  color: aliceblue;
}
.cart,.price{
  background-color: aliceblue;
  color:aliceblue;
  border:none;
  padding:10px;
  border-radius: 5px;
  margin-right:25px;
}
.cart:hover,.price:hover{
cursor: pointer;
}

/*-------CARTLIST-----*/
#clist{
  margin:10px;
  height:auto;
  padding:80px;
  border-radius: 5px;
}
.fa-regular{
  border-radius: 50%;
  background-color: aliceblue;
  padding:10px;
  font-size: 20px;
  margin-left:40px;
  box-shadow: 1px -1px 10px rgb(77, 77, 77);
}
.fa-regular:hover{
  cursor: pointer;
}
/*-----------*/
.Card:hover{
  height:30rem;
  width:20rem;
  cursor: pointer;
}





























.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
